<template>
  <div>
    <el-row type="flex">
      <el-col :lg="{span:2,offset:6}" :xs="{span:8,offset:1}" :sm="{span:5,offset:2}">
        <div style="height: 400px;">
          <el-steps direction="vertical" :active="active" finish-status="success">
            <el-step title="Create Order"></el-step>
            <el-step title="Verify DNS1"></el-step>
            <el-step title="Verify DNS2"></el-step>
            <el-step title="Download Cert"></el-step>
          </el-steps>
        </div>
      </el-col>
      <el-col :lg="{span:11,offset:4}" :xs="{span:14,offset:0}" :sm="{span:16,offset:0}">
        <div>
          <el-carousel height="400px" direction="vertical" :autoplay="false" indicator-position="none" :loop="false"
                       :initial-index="active" ref="carousel">
            <el-carousel-item class="carousel">
              <div class="text">
                Enter your domain
              </div>
              <div class="input">
                <el-form :model="first" ref="first" label-width="0px" class="ms-content" :rules="rules.first">
                  <el-form-item prop="domain">
                    <el-input v-model="first.domain" placeholder="domain"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="text">
                Wildcard?
                <el-tooltip placement="right" effect="light">
                  <div slot="content">
                    true: example.com and *.example.com<br/>
                    false: example.com and www.example.com
                  </div>
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </div>
              <div class="wildcard">
                <el-radio-group v-model="first.wildcard" size="medium">
                  <el-radio-button :label="true"></el-radio-button>
                  <el-radio-button :label="false"></el-radio-button>
                </el-radio-group>
              </div>
              <div>
                <el-button type="primary" @click="firstStep" v-loading.fullscreen.lock="fullscreen.first">Next step
                </el-button>
              </div>
            </el-carousel-item>
            <el-carousel-item class="carousel">
              <div class="text" v-if="active===1">
                txt record: {{ info.challengeInfos[0].record }}
              </div>
              <div class="text" v-if="active===1">
                token: {{ info.challengeInfos[0].token }}
              </div>
              <div class="text" v-if="active===1">
                status: {{ info.challengeInfos[0].status }}
              </div>
              <div>
                <el-button type="primary" @click="stepTwo" v-loading.fullscreen.lock="fullscreen.verify1">Verify
                </el-button>
              </div>
            </el-carousel-item>
            <el-carousel-item class="carousel">
              <div class="text" v-if="active===2">
                txt record: {{ info.challengeInfos[1].record }}
              </div>
              <div class="text" v-if="active===2">
                token: {{ info.challengeInfos[1].token }}
              </div>
              <div class="text" v-if="active===2">
                status: {{ info.challengeInfos[1].status }}
              </div>
              <div>
                <el-button type="primary" @click="stepThree" v-loading.fullscreen.lock="fullscreen.verify2">Verify
                </el-button>
              </div>
            </el-carousel-item>
            <el-carousel-item class="carousel">
              <div>
                <el-button type="primary" @click="download" v-loading.fullscreen.lock="fullscreen.finalize">Download
                </el-button>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "@/util/request";

const api = require("@/util/api")
const StringUtils = require("../util/StringUtils.js")

export default {
  name: "AddCert",
  created() {
    this.checkLogin();
    const isNew = this.$route.params.isNew;
    if (StringUtils.isNotBlank(isNew) && isNew === 'false') {
      this.info = JSON.parse(localStorage.getItem('order'));
    } else {
      localStorage.removeItem('order')
      this.info = undefined;
    }

    if (this.info !== undefined) {
      if (this.info.challengeInfos.length === 1 && this.info.challengeInfos[0].status !== 'VALID') {
        this.active = 1;
      } else if (this.info.challengeInfos.length === 2 && this.info.challengeInfos[0].status === 'VALID' && this.info.challengeInfos[1].status !== 'VALID') {
        this.active = 2;
      } else if (this.info.challengeInfos.length === 2 && this.info.challengeInfos[1].status !== 'VALID') {
        this.active = 3;
      } else if (this.info.challengeInfos.length === 2 && this.info.challengeInfos[1].status === 'VALID' && this.info.hasCert === false) {
        this.active = 4;
      } else if (this.info.challengeInfos.length === 2 && this.info.challengeInfos[1].status === 'VALID' && this.info.hasCert === true) {
        this.active = 5;
      } else {
        this.active = 0;
      }
    }
  },
  data() {
    const checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/
      if (StringUtils.isBlank(value)) {
        callback(new Error('Domain is required'))
      } else {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter your vaild Domain'))
        }
      }
    };
    return {
      active: 0,
      first: {
        domain: '',
        wildcard: false
      },
      rules: {
        first: {
          domain: [{validator: checkEmail, trigger: 'change'}],
        }
      },
      info: {
        createdAt: '',
        domain: '',
        id: '',
        status: '',
        url: '',
        wildcard: '',
        hasCert: '',
        challengeInfos: [
          {
            createdAt: '',
            id: '',
            record: '',
            status: '',
            token: '',
            type: '',
            url: ''
          }
        ],
      },
      fullscreen: {
        first: false,
        verify1: false,
        verify2: false,
        finalize: false
      }
    };
  },
  methods: {
    checkLogin() {
      const user = window.localStorage.getItem("user");
      if (StringUtils.isBlank(user)) {
        this.$router.push('/login')
      }
    },
    firstStep() {
      this.fullscreen.first = true;
      service.post(api.order, {
        domain: this.first.domain,
        wildcard: this.first.wildcard,
      }).then((res) => {
        this.$message.success('Create Order Successful');
        this.info = res.data;
        this.fullscreen.first = false;
        this.active = 1;
        this.$refs.carousel.setActiveItem(1);
      })
    },
    stepTwo() {
      this.fullscreen.verify1 = true;
      service.put(api.orderBase + this.info.id + '/verification').then(() => {
        service.get(api.orderBase + this.info.id + "/status")
            .then((res) => {
              this.info = res.data;
              window.localStorage.setItem('order', JSON.stringify(this.info));
              this.$message.success('Verify dns1 Successful');
              this.fullscreen.verify1 = false;
              this.active = 2;
              this.$refs.carousel.setActiveItem(2)
            });
      })
    },
    stepThree() {
      this.fullscreen.verify2 = true;
      service.put(api.orderBase + this.info.id + '/verification').then(() => {
        service.get(api.orderBase + this.info.id + "/status")
            .then((res) => {
              this.info = res.data;
              window.localStorage.setItem('order', JSON.stringify(this.info));
              this.$message.success('Verify dns2 Successful');
              this.fullscreen.verify2 = false;
              this.active = 3;
              this.$refs.carousel.setActiveItem(3)
            });
      })
    },
    download() {
      this.fullscreen.finalize = true;
      service.get(api.orderBase + this.info.id + "/finalize")
          .then((res) => {
            this.fullscreen.finalize = false;
            this.active = 4;
            this.$refs.carousel.setActiveItem(4)
            window.open('/cert/api/cert/' + this.info.id + '/download');
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  text-align: left;

  .text {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;
  }

  .input {
    .el-input {
      width: 300px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      .el-input {
        width: 200px;
      }
    }
  }

  .wildcard {
    margin-bottom: 20px;
  }
}
</style>

<style scoped>

</style>
