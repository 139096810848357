<template>
  <div>
    <el-divider></el-divider>
    <div class="donate">
      <div class="power-by"> Power By</div>
      <img src="../assets/monero-logo.png" class="logo" @click="goMonero" alt="monero-logo">
      <el-tooltip class="item" effect="dark" content="Click Copy" placement="top">
        <div class="address" @click="copy">
          83QeS7Xe3j1UCsVra5kgAyQiu8kLHWguLeRJ7qNjncrab8KqUmazaNHDmWJeMQHVsRBp4isrV5kfTcrUU5WSLLmrKvQuW8u<i
            class="el-icon-document-copy"></i>
        </div>
      </el-tooltip>
      <img src="../assets/xmr-qr.png" class="logo" @click="goMonero" alt="xmr-qr">
    </div>
  </div>
</template>

<script>
export default {
  name: "Donate",
  methods: {
    goMonero() {
      window.open("https://www.getmonero.org/")
    },
    copy() {
      document.execCommand("Copy", null, "83QeS7Xe3j1UCsVra5kgAyQiu8kLHWguLeRJ7qNjncrab8KqUmazaNHDmWJeMQHVsRBp4isrV5kfTcrUU5WSLLmrKvQuW8u")
      this.$message({
        message: 'Copy Success',
        type: 'success',
        offset: 80
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.donate {
  text-align: center;
  margin: 0 auto;

  .power-by {
    color: #de8216;
    font-weight: 700;
    cursor: pointer;
    font-size: 30px;
    padding: 20px 0;
  }

  .logo {
    width: 200px;
    padding-bottom: 20px;
    cursor: pointer;
  }

  .address {
    margin: 0 auto;
    width: 320px;
    word-wrap: break-word;
    cursor: pointer;
  }
}

</style>

<style>
@media screen and (max-width: 730px) {
  .el-message {
    min-width: 280px;
  }
}
</style>