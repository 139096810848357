<template>
  <div class="login-container">
    <div>
      <el-form :model="param" ref="login" label-width="0px" class="ms-content" :rules="rules">
        <el-form-item prop="email">
          <el-input v-model="param.email" placeholder="Email">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
            <el-button slot="append" v-if="!isLogin" style="padding: 12px 10px;font-size: 10px"
                       @click="sendSignupEmail">Send Code
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="code" v-if="!isLogin">
          <el-input v-model="param.code" placeholder="Email code">
            <el-button slot="prepend" icon="el-icon-warning-outline"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="Password" v-model="param.password" @keyup.enter.native="login">
            <el-button slot="prepend" icon="el-icon-lock"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password2" v-if="!isLogin">
          <el-input type="password" placeholder="Repeat the password" v-model="param.password2"
                    @keyup.enter.native="signup">
            <el-button slot="prepend" icon="el-icon-lock"></el-button>
          </el-input>
        </el-form-item>
        <div class="forgot-password" v-if="isLogin" @click="goResetPassword">Forgot password?</div>
        <div class="login-btn">
          <el-button type="primary" v-if="isLogin" @click="login()" v-loading.fullscreen.lock="fullscreen.login">Login
          </el-button>
          <el-button type="primary" v-if="!isLogin" @click="signup()" v-loading.fullscreen.lock="fullscreen.signup">
            Signup
          </el-button>
          <div class="create" v-if="isLogin">New to Let's SSL?<a @click="change">Create an account</a></div>
          <div class="create" v-if="!isLogin">Has an account?<a @click="change">Go to login</a></div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import service from "@/util/request";

const api = require("@/util/api")
const StringUtils = require("@/util/StringUtils")

export default {
  name: "Login",
  created() {
    this.checkLogin()
  },
  data() {
    const checkEmail = (rule, value, callback) => {
      const mailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (StringUtils.isBlank(value)) {
        callback(new Error('Email is required'))
      } else {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter your vaild email'))
        }
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (StringUtils.isBlank(value)) {
        callback(new Error('Password is required'))
      } else {
        if (this.isLogin) {
          callback()
        } else {
          if (this.param.password2 !== value && StringUtils.isNotBlank(this.param.password2)) {
            callback(new Error('Two passwords are different'))
          } else {
            callback()
          }
        }
      }
    };
    const checkPassword2 = (rule, value, callback) => {
      if (!this.isLogin) {
        if (StringUtils.isBlank(value)) {
          callback(new Error('Please repeat the password'))
        } else {
          if (this.param.password !== value) {
            callback(new Error('Two passwords are different'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    };
    const checkCode = (rule, value, callback) => {
      if (!this.isLogin) {
        if (StringUtils.isBlank(value)) {
          callback(new Error('Please enter the email code'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    return {
      param: {
        email: '',
        password: '',
        password2: '',
        code: ''
      },
      isLogin: true,
      rules: {
        email: [{validator: checkEmail, trigger: 'change'}],
        password: [{validator: checkPassword, trigger: ['change', 'blur']}],
        password2: [{validator: checkPassword2, trigger: ['change', 'blur']}],
        code: [{validator: checkCode, trigger: 'change'}]
      },
      fullscreen: {
        login: false,
        signup: false
      }
    };
  },
  methods: {
    login() {
      if (this.isLogin) {
        this.$refs['login'].validate((valid) => {
          if (valid) {
            this.fullscreen.login = true;
              service.post(api.login, {
                email: this.param.email,
                password: this.param.password,
                ip: ""
              }).then(() => {
                this.$message.success('Login Successful');
                localStorage.setItem('user', this.param.email);
                this.fullscreen.login = false;
                this.$router.push('/dashboard');
              });
          }
        });
      }
    },
    sendSignupEmail() {
      if (!this.isLogin) {
        service.post(api.signupEmail, {
          email: this.param.email
        }).then(() => {
          this.$message.success('Send Email Successful');
        })
      }
    },
    signup() {
      if (!this.isLogin) {
        this.$refs['login'].validate((valid) => {
          if (valid) {
            this.fullscreen.signup = true;
            service.post(api.signup, {
              email: this.param.email,
              password: this.param.password,
              code: this.param.code
            }).then(() => {
              this.$message.success('Signup Successful');
              localStorage.setItem('user', this.param.email);
              this.fullscreen.signup = false;
              this.$router.push('/dashboard')
            })
          }
        });
      }
    },
    change() {
      this.isLogin = !this.isLogin
    },
    goResetPassword() {
      this.$router.push("/resetPassword")
    },
    checkLogin() {
      const user = window.localStorage.getItem("user");
      if (StringUtils.isNotBlank(user)) {
        this.$router.push('/dashboard')
      }
    }
  }
}
</script>

<style lang="scss">
.create {
  text-align: center;
  font-size: 15px;
  padding: 20px 0;
  border: 1px solid #abaeb4;
  border-radius: 4px;

  a {
    color: #409EFF;
    cursor: pointer;
    padding-left: 10px;
  }
}

</style>

<style scoped>
.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-container {
  margin: 0 auto;
  width: 400px;
}

.forgot-password {
  color: #409EFF;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: right;
  font-size: 13px;
}

@media screen and (max-width: 769px) {
  .login-container {
    width: 100%;
  }
}

</style>
