<template>
  <div class="dashboard-container">
    <div class="d-float">
      <div class="handle-box">
        <el-input v-model="search.id" placeholder="id" clearable></el-input>
        <el-input v-model="search.domain" placeholder="domain" clearable></el-input>
        <el-button @click="list(1)" v-loading.fullscreen.lock="listLoading">Search</el-button>
        <el-button @click="clear">Reset</el-button>
        <el-button type="success" @click="gotoAddCert">Add</el-button>
        <el-table :data="tableData.content" border>
          <el-table-column prop="id" label="id" width="180px"></el-table-column>
          <el-table-column prop="createdAt" label="create time" width="180px"></el-table-column>
          <el-table-column prop="domain" label="domain" width="200px"></el-table-column>
          <el-table-column label="wildcard" width="121px">
            <template slot-scope="scope">
              {{ scope.row.wildcard ? 'yes' : 'no' }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="status" width="100"></el-table-column>
          <el-table-column prop="url" label="url" width="400px"></el-table-column>
          <el-table-column label="operation" width="130px">
            <template slot-scope="scope">
              <el-button type="primary" @click="gotoInfo(scope.row.id)" v-loading.fullscreen.lock="fullscreenLoading">
                View details
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-bottom: 20px"></div>
        <el-pagination background layout="prev, pager, next" small
                       :current-page="query.page"
                       :page-size="query.size"
                       :total="tableData.totalElements"
                       @current-change="list"></el-pagination>
      </div>
    </div>
    <el-drawer title="details" :visible.sync="table" direction="rtl" :size="drawerSize">
      <div class="drawer">
        <div class="drawer-info">id: {{ info.id }}</div>
        <div class="drawer-info">create time: {{ info.createdAt }}</div>
        <div class="drawer-info">domain: {{ info.domain }}</div>
        <div class="drawer-info">wildcard: {{ info.wildcard }}</div>
        <div class="drawer-info">status: {{ info.status }}</div>
        <div class="drawer-info">url: {{ info.url }}</div>
        <div class="drawer-info">challenges:</div>
        <el-table :data="info.challengeInfos" border>
          <el-table-column prop="record" label="txt record" width="210px"></el-table-column>
          <el-table-column prop="token" label="token" width="360px"></el-table-column>
          <el-table-column prop="type" label="type" width="70px"></el-table-column>
          <el-table-column prop="status" label="status" width="100px"></el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button type="primary" v-if="info.status!=='READY'" @click="verify">Verify</el-button>
          <el-button type="primary" v-if="info.status==='READY'" @click="download" v-loading.fullscreen.lock="finalize">
            Download
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import service from "@/util/request";

const api = require("@/util/api")
const StringUtils = require("@/util/StringUtils")
export default {
  name: "Dashboard",
  created() {
    this.checkLogin();
    this.list(this.search.page);
  },
  data() {
    return {
      id: '',
      query: {
        page: 1,
        size: 10
      },
      tableData: {
        totalElements: 0,
        content: []
      },
      search: {
        id: '',
        domain: '',
      },
      table: false,
      info: {
        createdAt: '',
        domain: '',
        id: '',
        status: '',
        url: '',
        wildcard: '',
        hasCert: '',
        challengeInfos: [
          {
            createdAt: '',
            id: '',
            record: '',
            status: '',
            token: '',
            type: '',
            url: ''
          }
        ],
      },
      drawerSize: '50%',
      fullscreenLoading: false,
      listLoading: false,
      finalize: false
    };
  },
  methods: {
    gotoInfo(id) {
      this.fullscreenLoading = true;
      const width = document.body.clientWidth;
      this.drawerSize = width > 768 ? '50%' : '90%';
      service.get(api.orderBase + id + "/status")
          .then((res) => {
            this.info = res.data;
            this.table = true;
            this.fullscreenLoading = false;
          });
    },
    gotoAddCert() {
      this.$router.push({
        path: '/addCert',
        name: 'AddCert',
        params: {
          isNew: 'true'
        }
      })
    },
    list(page) {
      page = page > 0 ? page - 1 : 0;
      this.listLoading = true;
      service.get(api.order + "?id=" + this.search.id + "&domain=" + this.search.domain + "&page=" + page + "&size=" + this.query.size)
          .then((res) => {
            this.tableData = res.data;
            this.listLoading = false;
          });
    },
    clear() {
      this.search = {
        id: '',
        domain: '',
      }
      this.list(1);
    },
    checkLogin() {
      const user = window.localStorage.getItem("user");
      if (StringUtils.isBlank(user)) {
        this.$router.push('/login')
      }
    },
    verify() {
      window.localStorage.setItem('order', JSON.stringify(this.info));
      this.$router.push({
        path: '/addCert',
        name: 'AddCert',
        params: {
          isNew: 'false'
        }
      })
    },
    download() {
      this.finalize = true;
      service.get(api.orderBase + this.info.id + "/finalize")
          .then((res) => {
            this.finalize = false;
            window.open('/cert/api/cert/' + this.info.id + '/download');
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  margin-left: 30px;
  margin-right: 10px;
  text-align: left;

  .drawer-info {
    margin-bottom: 10px;
    text-align: left;
    word-wrap: break-word;
    word-break: normal;
    font-weight: 700;
    font-size: 13px;
  }
}
</style>

<style scoped>
.el-input {
  width: 300px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.el-button {
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 10px;
}

.d-float {
  margin: 0 auto;
  width: 80%
}

.dashboard-container {
  padding: 30px 20px;
  background: #fff;
  border-top: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
}

.handle-box {
  margin-bottom: 20px;
}

@media screen and (max-width: 769px) {
  .d-float {
    width: 100%;
  }
}
</style>
